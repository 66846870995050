import {
  FETCH_CALENDAR_REQUEST,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE,
  FETCH_CALENDAR_RESULTS_REQUEST,
  FETCH_CALENDAR_RESULTS_SUCCESS,
  FETCH_CALENDAR_RESULTS_FAILURE,
  FETCH_CALENDAR_NEXT_RACE_REQUEST,
  FETCH_CALENDAR_NEXT_RACE_SUCCESS,
  FETCH_CALENDAR_NEXT_RACE_FAILURE,
} from "../types/calendarTypes";

export const calendarReducer = (state = { calendars: [] }, action) => {
  switch (action.type) {
    case FETCH_CALENDAR_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CALENDAR_SUCCESS:
      return {
        loading: false,
        calendars: [...state.calendars, action.payload],
      };
    case FETCH_CALENDAR_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const calendarResultsReducer = (state = { results: [] }, action) => {
  switch (action.type) {
    case FETCH_CALENDAR_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CALENDAR_RESULTS_SUCCESS:
      return {
        loading: false,
        results: [...state.results, action.payload],
      };
    case FETCH_CALENDAR_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const initialNextRaceState = {
  loading: false,
  error: null,
  nextRace: null,
};

export const calendarNextRaceReducer = (
  state = initialNextRaceState,
  action
) => {
  switch (action.type) {
    case FETCH_CALENDAR_NEXT_RACE_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case FETCH_CALENDAR_NEXT_RACE_SUCCESS:
      return {
        loading: false,
        nextRace: action.payload,
      };
    case FETCH_CALENDAR_NEXT_RACE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
