import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./rootReducer";
import { loadState, saveState } from './sessionStorage';

const middleware = [thunk];

// Load state from session storeage (if available)
const persistedState = loadState();

const store = createStore(rootReducer, persistedState, composeWithDevTools(applyMiddleware(...middleware)));

// Save any state modifications to session  storage
store.subscribe(() => {
    saveState(store.getState());
});

export default store;