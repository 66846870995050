// Component to handle race results toggling for each card.
// Arrow functions on onClick would create each time a new independant function.

import React, { useState } from 'react'

const ResultsButton = ({ roundCardId, buttonText }) => {

    const [active, setActive] = useState(false);

    const handleClick = () => {
        const roundCard = document.querySelector(`#${roundCardId}`);
        roundCard.classList.toggle("race-card-results-active");
        setActive(!active);
    }

    return (
        <div className="w-100">
            <button onClick={handleClick} className={`race-toggle-btn results-button text-center w-100`}>
                {active
                    ? <span>Circuit <img src="../assets/icons/chevron-down-solid.svg" alt="down icon" /></span>
                    : <span>Results <img src="/assets/icons/chevron-up-solid.svg" alt="up icon" /></span>
                }
            </button>
        </div>
    )
}

export default ResultsButton
