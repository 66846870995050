import { combineReducers } from "redux";
import { driverStandingsReducer } from "../reducers/driverReducers";
import {
  constructorStandingsReducer,
  constructorResultsReducer,
} from "../reducers/constructorReducers";
import {
  calendarReducer,
  calendarResultsReducer,
  calendarNextRaceReducer,
} from "../reducers/calendarReducers";

const rootReducer = combineReducers({
  driverStandings: driverStandingsReducer,
  constructorStandings: constructorStandingsReducer,
  constructorResults: constructorResultsReducer,
  calendarData: calendarReducer,
  calendarResults: calendarResultsReducer,
  calendarNextRace: calendarNextRaceReducer,
});

export default rootReducer;
