import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendar } from "../redux/actions/calendarActions";
import { Alert, Spinner, Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const CalendarTable = ({ year }) => {

    const dispatch = useDispatch();

    const calendarData = useSelector(state => state.calendarData);
    const { loading: loadingSeasonRaces, error: errorSeasonRaces, calendars } = calendarData;

    const [seasonRaces, setSeasonRaces] = useState("");

    useEffect(() => {
        const currentYearCalendar = calendars.find(calendar => calendar.year === year)
        if (currentYearCalendar) {
            setSeasonRaces(currentYearCalendar.seasonRaces);
        } else {
            dispatch(fetchCalendar(year));
        }
    }, [dispatch, year, calendars, setSeasonRaces]);

    return (
        <div>
            {loadingSeasonRaces
                ? (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                )
                : errorSeasonRaces
                    ? <Alert variant="danger">{errorSeasonRaces}</Alert>
                    : seasonRaces &&
                    (
                        <Table bordered striped hover responsive="sm" className="text-center results-table">
                            <tbody>
                                <tr>
                                    <th>
                                        Round
                                    </th>
                                    <th>
                                        Grand Prix
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                        Circuit
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                        Location
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                </tr>
                                {seasonRaces.map((roundRace, index) => (
                                    <tr key={index}>
                                        <td>
                                            {roundRace.round}
                                        </td>
                                        <td>
                                            {roundRace.raceName}
                                        </td>
                                        <td className="d-none d-md-table-cell">
                                            {roundRace.Circuit.circuitName}
                                        </td>
                                        <td className="d-none d-md-table-cell">
                                            {roundRace.Circuit.Location.locality},&nbsp;{roundRace.Circuit.Location.country}&nbsp;
                                            <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.en_short_name === roundRace.Circuit.Location.country)[0].alpha_2_code.toLowerCase()}.svg`}
                                                alt={`${roundRace.Circuit.Location.country} flag`}
                                                style={{ height: "1em", maxWidth: "1.5em", borderRadius: "2px" }} />
                                        </td>
                                        <td>
                                            {roundRace.date}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    )
            }
        </div>
    )
}

export default CalendarTable
