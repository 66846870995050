import React, { useState } from 'react';
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";

const Header = () => {

    const [opacity, setOpacity] = useState(true);

    const handleClick = (ev) => {
        setOpacity(!opacity);
    }

    return (
        <header>
            <Navbar
                bg={window.location.pathname === "/" && opacity && "transparent"}
                className="navbar-custom" variant="dark"
                expand="sm" fixed="top" collapseOnSelect>
                <Container>
                    <Navbar.Brand href="/">
                        Formula Now
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleClick} />
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="ml-auto">
                            <NavDropdown title="Standings" id="basic-nav-dropdown">
                                <NavDropdown.Item href="/standings/drivers">Drivers</NavDropdown.Item>
                                <NavDropdown.Item href="/standings/constructors">Constructors</NavDropdown.Item>
                            </NavDropdown>
                            <Nav.Link href="/calendar">Calendar</Nav.Link>
                            <Nav.Link href="/history">Historical Data</Nav.Link>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}

export default Header
