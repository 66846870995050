import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { fetchDriverStandings } from "../redux/actions/driverActions";
import { Alert, Container, Row, Col } from 'react-bootstrap';
import DriverResultsTable from './DriverResultsTable';
import DriverInfoCard from './DriverInfoCard';
import axios from "axios";


const DriverProfileScreen = () => {

    const { driverId: paramsDriverId } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    const driverStandings = useSelector(state => state.driverStandings);
    const { yearlyStandings } = driverStandings;

    const [driverInfo, setDriverInfo] = useState("");
    const [driverWiki, setDriverWiki] = useState("");
    const [driverWikiError, setDriverWikiError] = useState("");

    useEffect(() => {
        document.title = "FN: Driver Profile"
    }, []);

    // Grab season standings
    useEffect(() => {
        const currentYearStandings = yearlyStandings.find(standings => standings.year === "current");
        if (!currentYearStandings) {
            dispatch(fetchDriverStandings());
        } else {
            const driverInfo = currentYearStandings.standings.filter(driverInfo => driverInfo.Driver.driverId === paramsDriverId);
            // If driver queried doesn't exist, redirect.
            if (driverInfo.length === 0) {
                history.push("/404");
            }
            setDriverInfo(...driverInfo);

        }
    }, [dispatch, yearlyStandings, setDriverInfo, paramsDriverId, history])

    // Grab data from Wikipedia.
    useEffect(() => {
        if (driverInfo) {
            const regex = /[^/]+$/;
            const driverWikiTitle = driverInfo.Driver.url.match(regex)[0];
            // Get page image and extract for a driver.
            axios.get(`https://en.wikipedia.org/w/api.php?format=json&action=query&prop=pageimages|extracts&piprop=original&exintro&explaintext&redirects=1&titles=${driverWikiTitle}&origin=*`)
                .then(response => {
                    const pageId = Object.keys(response.data.query.pages)[0];
                    setDriverWiki(response.data.query.pages[pageId]);
                })
                .catch(error => setDriverWikiError(error.message));
        }
    }, [dispatch, driverInfo, setDriverWiki, setDriverWikiError])

    return (
        <>
            {driverWikiError
                ? <Alert variant="danger">{driverWikiError}</Alert>
                : driverWiki &&
                (
                    <Container className="page-container">
                        <h1 className="profile-title main-heading">{driverWiki.title}</h1>

                        {/* Wiki data */}
                        <Row>
                            <Col className="profile-main-info py-5">

                                {/* Profile info card */}
                                <DriverInfoCard driverWiki={driverWiki} driverInfo={driverInfo} />

                                <p>{driverWiki.extract}</p>
                                <a href={driverInfo.Driver.url} className="wiki-link">Continue reading on Wikipedia...</a>
                            </Col>
                        </Row>

                        {/* Results table */}
                        <Row className="text-center py-5">
                            <DriverResultsTable paramsDriverId={paramsDriverId} />
                        </Row>
                    </Container>
                )
            }
        </>
    )
}

export default DriverProfileScreen
