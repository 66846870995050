import {
    FETCH_DRIVER_STANDINGS_REQUEST,
    FETCH_DRIVER_STANDINGS_SUCCESS,
    FETCH_DRIVER_STANDINGS_FAILURE
} from "../types/driverTypes";

export const driverStandingsReducer = (state = { yearlyStandings: [] }, action) => {
    switch (action.type) {
        case FETCH_DRIVER_STANDINGS_REQUEST:
            return {
                ...state,
                loading: true,
                error: ""
            };
        case FETCH_DRIVER_STANDINGS_SUCCESS:
            return {
                loading: false,
                yearlyStandings: [...state.yearlyStandings, action.payload]
            };
        case FETCH_DRIVER_STANDINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}