import React, { useEffect, useState } from 'react';
import { fetchCalendarResults } from "../redux/actions/calendarActions";
import { useDispatch, useSelector } from "react-redux";
import { Alert, Spinner, Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const DriverResultsTable = ({ paramsDriverId }) => {


    const dispatch = useDispatch();

    const calendarResults = useSelector(state => state.calendarResults);
    const { loading: loadingSeasonResults, error: errorSeasonResults, results } = calendarResults;

    const [seasonResults, setSeasonResults] = useState("");

    // Grab season results.
    useEffect(() => {
        const currentYearResults = results.find(yearlyResults => yearlyResults.year === "current")
        if (currentYearResults) {
            setSeasonResults(currentYearResults.seasonResults);
        } else {
            dispatch(fetchCalendarResults());
        }
    }, [dispatch, results, setSeasonResults]);

    return (
        <>
            {loadingSeasonResults
                ?
                (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                )
                : errorSeasonResults
                    ? <Alert variant="danger">{errorSeasonResults}</Alert>
                    : seasonResults &&
                    (
                        <Table striped bordered hover responsive="lg" className="results-table">
                            <tbody>
                                <tr>
                                    <th>
                                        Round
                                    </th>
                                    <th>
                                        Grand Prix
                                    </th>
                                    <th>
                                        Circuit Name
                                    </th>
                                    <th>
                                        Country
                                    </th>
                                    <th>
                                        Date
                                    </th>
                                    <th>
                                        Status
                                    </th>
                                    <th>
                                        Grid start
                                    </th>
                                    <th>
                                        Position
                                    </th>
                                    <th>
                                        Points
                                    </th>
                                    <th>
                                        Fastest Lap
                                    </th>
                                </tr>

                                {seasonResults.map((roundResults, index) =>
                                    // Searching through all races completed this season
                                    roundResults.Results.filter(driverResults =>
                                        // Searching through positions obtained during a race for drivers result
                                        driverResults.Driver.driverId === paramsDriverId)
                                        .map(driverResults => (
                                            <tr key={index}>
                                                <td>
                                                    {index + 1}
                                                </td>
                                                <td>
                                                    {roundResults.raceName}
                                                </td>
                                                <td>
                                                    {roundResults.Circuit.circuitName}
                                                </td>
                                                <td>
                                                    {roundResults.Circuit.Location.country}&nbsp;
                                                        <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.en_short_name === roundResults.Circuit.Location.country)[0].alpha_2_code.toLowerCase()}.svg`}
                                                        alt={`${roundResults.Circuit.Location.country} flag`} />
                                                </td>
                                                <td>
                                                    {roundResults.date}
                                                </td>
                                                <td>
                                                    {driverResults.status}
                                                </td>
                                                <td>
                                                    {driverResults.grid}
                                                </td>
                                                <td>
                                                    {driverResults.positionText === "R" ? "Retired" : driverResults.positionText}
                                                </td>
                                                <td>
                                                    {driverResults.points}
                                                </td>
                                                <td>
                                                    {driverResults.hasOwnProperty("FastestLap") ? driverResults.FastestLap.Time.time : "No Time"}
                                                </td>
                                            </tr>
                                        ))
                                )}
                            </tbody>
                        </Table>
                    )
            }
        </>
    )
}

export default DriverResultsTable
