import {
    FETCH_DRIVER_STANDINGS_REQUEST,
    FETCH_DRIVER_STANDINGS_SUCCESS,
    FETCH_DRIVER_STANDINGS_FAILURE
} from "../types/driverTypes";
import axios from "axios";

export const fetchDriverStandings = (year = "current") => {
    return (dispatch) => {
        dispatch({ type: FETCH_DRIVER_STANDINGS_REQUEST });
        axios.get(`https://ergast.com/api/f1/${year}/driverStandings.json`)
            .then(response => dispatch({
                type: FETCH_DRIVER_STANDINGS_SUCCESS,
                payload: {
                    year,
                    standings: response.data.MRData.StandingsTable.StandingsLists[0].DriverStandings
                }
            }))
            .catch(error => dispatch({
                type: FETCH_DRIVER_STANDINGS_FAILURE,
                payload: error.message
            }));
    };
};