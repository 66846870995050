import React, { useState, useEffect } from 'react';
import { Container, Col, Tabs, Tab } from 'react-bootstrap';
import SelectInput from '../shared/SelectInput';
import CalendarTable from './CalendarTable';
import DriverStandingsTable from './DriverStandingsTable';
import ConstructorsStandingsTable from './ConstructorsStandingsTable';

const HistoryScreen = () => {

    const [year, setYear] = useState("");

    const thisYear = (new Date()).getFullYear();
    const firstYear = 1958;
    const yearList = Array.from([...Array(thisYear - firstYear).keys()], i => i + 1958).reverse();

    let options = yearList.map(year => ({ value: year.toString(), label: year }));

    const handleYearSelection = (year) => {
        setYear(year);
    }

    useEffect(() => {
        document.title = "FN: Historical Data"
    }, []);

    return (
        <Container className="page-container">
            <h1 className="main-heading">Historical Data</h1>
            <Col md={{ span: 6, offset: 3 }} className="my-5">
                <SelectInput options={options} parentCallBack={handleYearSelection} />
            </Col>
            {year
                ? (<div id="historical-tabs">
                    <Tabs defaultActiveKey="profile" id="historical-tabs">
                        <Tab eventKey="calendar" title="Calendar">
                            <CalendarTable year={year} />
                        </Tab>
                        <Tab eventKey="driverStandings" title="Driver Standings">
                            <DriverStandingsTable year={year} />
                        </Tab>
                        <Tab eventKey="constructorStandings" title="Constructor Standings">
                            <ConstructorsStandingsTable year={year} />
                        </Tab>
                    </Tabs>
                </div>
                )
                : <h3 className="text-center">Select a year</h3>}

        </Container >
    )
}

export default HistoryScreen
