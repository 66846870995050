import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchConstructorStandings } from "../redux/actions/constructorActions";
import { Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const ConstructorStandingsScreen = () => {

    const dispatch = useDispatch();

    const constructorStandings = useSelector(state => state.constructorStandings);
    const { loading, error, yearlyStandings } = constructorStandings;

    const [standings, setStandings] = useState("");

    useEffect(() => {
        document.title = "FN: Constructor Standings"
    }, []);

    useEffect(() => {
        const currentYearStandings = yearlyStandings.find(standings => standings.year === "current")
        if (!currentYearStandings) {
            dispatch(fetchConstructorStandings())
        } else {
            setStandings(currentYearStandings.standings)
        }
    }, [dispatch, yearlyStandings, setStandings])

    return (
        <div>
            <Container className="page-container">
                <h1 className="main-heading">Constructor standings</h1>
                {loading
                    ? (
                        <div className="d-flex justify-content-center w-100">
                            <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                            </Spinner>
                        </div>
                    )
                    : error
                        ? <Alert variant="danger">{error}</Alert>
                        : standings &&
                        (
                            <>
                                <Row>
                                    {standings.map((team, index) => (
                                        <Col md={6} key={index}>
                                            <Link to={`/profile/team/${team.Constructor.constructorId}`} style={{ textDecoration: 'none' }}>
                                                <div className={`position-relative constructor-card ${team.Constructor.constructorId}`}>
                                                    <Row className="p-3">
                                                        <Col>
                                                            <Row className="pb-4">
                                                                <Col className={`constructor-card-logo ${team.Constructor.constructorId} col-auto d-flex justify-content-center align-items-center`}>
                                                                    <img className="constructor-logo" src={`/assets/constructors/${team.Constructor.constructorId}.jpg`} alt={`${team.Constructor.name}`} />
                                                                </Col>
                                                                <Col xs={12} sm={8}>
                                                                    <div className="d-flex align-items-center">
                                                                        {/* Obtain constructor nationality and from nationalities asset, obtain alpha code to grab corresponding flag */}
                                                                        <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.nationality === team.Constructor.nationality)[0].alpha_2_code.toLowerCase()}.svg`}
                                                                            alt={`${team.Constructor.nationality} flag`} />
                                                                        <span id="sdg-constructor-team">{team.Constructor.name}</span>
                                                                    </div>
                                                                    <div>
                                                                        <span id="sdg-constructor-wins">wins: {team.wins}</span>
                                                                    </div>
                                                                </Col>
                                                                <span id="sdg-points">{team.points}pts</span>
                                                            </Row>
                                                            <Row>
                                                                <Col xs={9} className="d-flex justify-content-center align-items-end">
                                                                    <img className="constructor-car-picture img-fluid" src={`/assets/cars/${team.Constructor.constructorId}.png`} alt={`${team.Constructor.name} car`} />
                                                                </Col>
                                                                <Col className="position-relative d-flex justify-content-end align-items-end">
                                                                    <span id="sdg-position">{team.position}</span>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </div>
                                            </Link>
                                        </Col>
                                    ))}
                                </Row>
                            </>
                        )
                }
            </Container >
        </div >
    )
}

export default ConstructorStandingsScreen
