import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap';

const NotFoundScreen = () => {

    useEffect(() => {
        document.title = "FN: Oops"
    }, []);

    return (
        <Container className="page-container not-found-container text-center">
            <span className="title-404">404</span>
            <span className="not-found">Not Found</span>
            <img src="/assets/404.svg" alt="not found" className="d-block m-auto" />
        </Container>
    )
}

export default NotFoundScreen
