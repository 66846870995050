import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Header from "./shared/Header";
import HomeScreen from "./home/HomeScreen";
import Footer from "./shared/Footer";
import DriverStandingsScreen from "./standings/DriverStandingsScreen";
import ConstructorStandingsScreen from "./standings/ConstructorStandingsScreen";
import DriverProfileScreen from "./profiles/DriverProfileScreen";
import TeamProfileScreen from "./profiles/TeamProfileScreen";
import CalendarScreen from "./calendar/CalendarScreen";
import HistoryScreen from "./history/HistoryScreen";
import NotFoundScreen from "./404/NotFoundScreen";
import "./styles/index.css";

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={HomeScreen} />
        <Route path="/standings/drivers" component={DriverStandingsScreen} />
        <Route
          path="/standings/constructors"
          component={ConstructorStandingsScreen}
        />
        <Route path="/calendar" component={CalendarScreen} />
        <Route
          path="/profile/driver/:driverId"
          component={DriverProfileScreen}
        />
        <Route
          path="/profile/team/:constructorId"
          component={TeamProfileScreen}
        />
        <Route path="/history" component={HistoryScreen} />
        <Route path="/404" component={NotFoundScreen} />
        <Route component={NotFoundScreen} />
      </Switch>
      <Footer />
    </Router>
  );
}

export default App;
