import React from 'react'
import { Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const TeamResultsTable = ({ seasonResults }) => {

    return (
        <Table bordered responsive="lg" className="team-results">
            <tbody>
                <tr>
                    <th>
                        Round
                    </th>
                    <th>
                        Grand Prix
                    </th>
                    <th>
                        Circuit Name
                    </th>
                    <th>
                        Country
                    </th>
                    <th>
                        Date
                    </th>
                    <th>
                        Driver
                    </th>
                    <th>
                        Status
                    </th>
                    <th>
                        Position
                    </th>
                    <th>
                        Points
                    </th>
                    <th>
                        Total points
                    </th>
                </tr>

                {seasonResults.map((roundResults, index) => {
                    return (
                        <React.Fragment key={index}>
                            <tr>
                                <td rowSpan={2}>
                                    {index + 1}
                                </td>
                                <td rowSpan={2}>
                                    {roundResults.raceName}
                                </td>
                                <td rowSpan={2}>
                                    {roundResults.Circuit.circuitName}
                                </td>
                                <td rowSpan={2}>
                                    {roundResults.Circuit.Location.country}&nbsp;
                                    <img className="national-flag" src={`/assets/flags/${nationalities
                                        .filter(nation => nation.en_short_name === roundResults.Circuit.Location.country)[0].alpha_2_code
                                        .toLowerCase()}.svg`}
                                        alt={`${roundResults.Circuit.Location.country} flag`} />
                                </td>
                                <td rowSpan={2}>
                                    {roundResults.date}
                                </td>
                                <td>
                                    {roundResults.Results[0].Driver.familyName}
                                </td>
                                <td>
                                    {roundResults.Results[0].status}
                                </td>
                                <td>
                                    {roundResults.Results[0].positionText}
                                </td>
                                <td>
                                    {roundResults.Results[0].points}
                                </td>
                                <td rowSpan={2}>
                                    {/* Total points */}
                                    {
                                        roundResults.Results
                                            .map(driverResults => driverResults.points)
                                            .reduce((pointsA, pointsB) => Number(pointsA) + Number(pointsB), 0)
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    {roundResults.Results[1].Driver.familyName}
                                </td>
                                <td>
                                    {roundResults.Results[1].status}
                                </td>
                                <td>
                                    {roundResults.Results[1].positionText}
                                </td>
                                <td>
                                    {roundResults.Results[1].points}
                                </td>
                            </tr>
                        </React.Fragment>
                    )
                })
                }
            </tbody>
        </Table>
    )
}

export default TeamResultsTable
