import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { fetchConstructorStandings } from "../redux/actions/constructorActions";
import { Alert, Spinner, Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const ConstructorsStandingsTable = ({ year }) => {

    const dispatch = useDispatch();

    const constructorStandings = useSelector(state => state.constructorStandings);
    const { loading, error, yearlyStandings } = constructorStandings;

    const [standings, setStandings] = useState("");

    useEffect(() => {
        const currentYearStandings = yearlyStandings.find(standings => standings.year === year)
        if (!currentYearStandings) {
            dispatch(fetchConstructorStandings(year))
        } else {
            setStandings(currentYearStandings.standings)
        }
    }, [dispatch, yearlyStandings, setStandings, year])

    return (
        <>
            {loading
                ? (
                    <div className="d-flex justify-content-center w-100">
                        <Spinner animation="border" role="status">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                )
                : error
                    ? <Alert variant="danger">{error}</Alert>
                    : standings &&
                    (
                        <Table bordered striped hover responsive="sm" className="text-center results-table">
                            <tbody>
                                <tr>
                                    <th>
                                        Position
                                    </th>
                                    <th>
                                        Constructor
                                    </th>
                                    <th>
                                        Points
                                    </th>
                                    <th className="d-none d-md-table-cell">
                                        Wins
                                    </th>
                                </tr>
                                {standings.map((constructor, index) => (
                                    <tr key={index}>
                                        <td>
                                            {constructor.positionText}
                                        </td>
                                        <td>
                                            {constructor.Constructor.name}&nbsp;
                                            <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.nationality === constructor.Constructor.nationality)[0].alpha_2_code.toLowerCase()}.svg`}
                                                alt={`${constructor.Constructor.nationality} flag`}
                                                style={{ height: "1em", maxWidth: "1.5em", borderRadius: "2px" }} />
                                        </td>
                                        <td>
                                            {constructor.points}
                                        </td>
                                        <td className="d-none d-md-table-cell">
                                            {constructor.wins}
                                        </td>
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    )
            }
        </>
    )
}

export default ConstructorsStandingsTable
