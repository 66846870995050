import {
    FETCH_CONSTRUCTOR_STANDINGS_REQUEST,
    FETCH_CONSTRUCTOR_STANDINGS_SUCCESS,
    FETCH_CONSTRUCTOR_STANDINGS_FAILURE,
    FETCH_CONSTRUCTOR_RESULTS_REQUEST,
    FETCH_CONSTRUCTOR_RESULTS_SUCCESS,
    FETCH_CONSTRUCTOR_RESULTS_FAILURE
} from "../types/constructorTypes";

export const constructorStandingsReducer = (state = { yearlyStandings: [] }, action) => {
    switch (action.type) {
        case FETCH_CONSTRUCTOR_STANDINGS_REQUEST:
            return {
                ...state,
                loading: true,
                error: ""
            };
        case FETCH_CONSTRUCTOR_STANDINGS_SUCCESS:
            return {
                loading: false,
                yearlyStandings: [...state.yearlyStandings, action.payload]
            };
        case FETCH_CONSTRUCTOR_STANDINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}

export const constructorResultsReducer = (state = { yearlyResults: [] }, action) => {
    switch (action.type) {
        case FETCH_CONSTRUCTOR_RESULTS_REQUEST:
            return {
                ...state,
                loading: true,
                error: ""
            };
        case FETCH_CONSTRUCTOR_RESULTS_SUCCESS:
            return {
                loading: false,
                yearlyResults: [...state.yearlyResults, action.payload]
            };
        case FETCH_CONSTRUCTOR_RESULTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };
        default:
            return state;
    }
}