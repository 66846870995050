import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCalendarNextRace } from "../redux/actions/calendarActions";
import { Container, Col, Row, Alert, Spinner } from "react-bootstrap";
import Countdown from "./Countdown";

const HomeScreen = () => {
  const dispatch = useDispatch();

  // const calendarData = useSelector((state) => state.calendarData);
  // const { loading, error, calendars } = calendarData;

  const calendarNextRace = useSelector((state) => state.calendarNextRace);
  const { loading, error, nextRace } = calendarNextRace;

  // const [nextRace, setNextRace] = useState("");

  useEffect(() => {
    document.title = "Formula Now";
  });

  // useEffect(() => {
  //   const currentYearCalendar = calendars.find(
  //     (calendar) => calendar.year === "current"
  //   );
  //   if (currentYearCalendar) {
  //     setNextRace(currentYearCalendar.nextRace);
  //   } else {
  //     dispatch(fetchCalendar());
  //   }
  // }, [dispatch, calendars, setNextRace]);

  useEffect(() => {
    if (!nextRace) {
      dispatch(fetchCalendarNextRace());
    }
  });

  return (
    <div className="home-div d-flex flex-column justify-content-center page-container">
      <Container>
        <Col lg={{ span: 8, offset: 2 }}>
          <div className="next-race d-flex flex-column justify-content-center align-items-center">
            {loading ? (
              <div className="d-flex justify-content-center w-100">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </div>
            ) : error ? (
              <Alert variant="danger">{error}</Alert>
            ) : (
              nextRace && (
                <>
                  <Row>
                    <span className="nextrace-subtitle">
                      Round {nextRace.round}
                    </span>
                  </Row>
                  <Row>
                    <span className="nextrace-title">{nextRace.raceName}</span>
                  </Row>
                  <Row className="mb-4">
                    <Countdown
                      raceTime={
                        Date.parse(nextRace.date + "T" + nextRace.time) / 1000
                      }
                    />
                  </Row>
                  <Row className="d-sm-none">
                    <img
                      src={`/assets/circuits/${nextRace.Circuit.circuitId}_small.png`}
                      alt={`${nextRace.raceName} circuit`}
                    />
                  </Row>
                  <Row className="d-none d-sm-block">
                    <Col md={{ span: 10, offset: 1 }}>
                      <img
                        src={`/assets/circuits/${nextRace.Circuit.circuitId}_big.png`}
                        alt={`${nextRace.raceName} circuit`}
                      />
                    </Col>
                  </Row>
                </>
              )
            )}
          </div>
        </Col>
      </Container>
    </div>
  );
};

export default HomeScreen;
