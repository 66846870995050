import React from 'react'
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const TeamInfoCard = ({ constructorInfo, constructorDrivers, constructorWiki }) => {
    return (
        <div className="card-container mx-auto mb-3 ml-sm-4 float-sm-right">

            {
                constructorWiki.original
                    ? <img className="profile-card-picture" src={constructorWiki.original.source} alt={`${constructorWiki.title}`} />
                    : <img className="profile-card-picture" src={`/assets/cars/${constructorInfo.Constructor.constructorId}.png`} alt={`${constructorWiki.title}`} />
            }

            <Table borderless size="sm">
                <tbody>
                    <tr>
                        <th>
                            Nationality
                        </th>
                        <td>
                            {constructorInfo.Constructor.nationality} <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.nationality === constructorInfo.Constructor.nationality)[0].alpha_2_code.toLowerCase()}.svg`}
                                alt={`${constructorInfo.Constructor.nationality} flag`} />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Position
                        </th>
                        <td>
                            <Link to={"/standings/constructors"}>
                                {constructorInfo.positionText}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Wins
                        </th>
                        <td>
                            {constructorInfo.wins}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Points
                        </th>
                        <td>
                            {constructorInfo.points}
                        </td>
                    </tr>
                    <tr>
                        <th rowSpan={2}>
                            Drivers
                        </th>
                        <td>
                            <Link to={`/profile/driver/${constructorDrivers[0].driverId}`}>
                                {constructorDrivers[0].familyName}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <Link to={`/profile/driver/${constructorDrivers[1].driverId}`}>
                                {constructorDrivers[1].familyName}
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default TeamInfoCard
