import React, { useState, useEffect } from 'react';

const Countdown = ({ raceTime }) => {

    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [hours, setHours] = useState(0);
    const [days, setDays] = useState(0);

    const currentTime = Math.floor(Date.now() / 1000) // Convert milliseconds to seconds

    const secondsDiff = raceTime - currentTime;

    const [timeDiff, setTimeDiff] = useState(secondsDiff);

    useEffect(() => {
        const countDown = setInterval(() => {
            setDays(Math.floor(timeDiff / (3600 * 24)));
            setHours(Math.floor(timeDiff % (3600 * 24) / 3600));
            setMinutes(Math.floor(timeDiff % 3600 / 60));
            setSeconds(Math.floor(timeDiff % 60));
            setTimeDiff(prevState => prevState - 1)
        }, 1000)
        return () => {
            clearInterval(countDown);
        };
    }, [timeDiff])

    return (
        <div id="countdown" className="d-flex justify-content-center align-items-center px-5">
            <div className="time-unit-div">
                <div>
                    <span className="time-units-digits text-danger font-weight-bold">{days.toString().padStart(2, "0")}</span>
                </div>
                <div>
                    <span className="time-units-word">Days</span>
                </div>
            </div>
            <div className="time-unit-div">
                <div>
                    <span className="time-units-digits text-danger font-weight-bold">{hours.toString().padStart(2, "0")}</span>
                </div>
                <div>
                    <span className="time-units-word">Hours</span>
                </div>
            </div>
            <div className="time-unit-div">
                <div>
                    <span className="time-units-digits text-danger font-weight-bold">{minutes.toString().padStart(2, "0")}</span>
                </div>
                <div>
                    <span className="time-units-word">Minutes</span>
                </div>
            </div>
            <div className="time-unit-div">
                <div>
                    <span className="time-units-digits text-danger font-weight-bold">{seconds.toString().padStart(2, "0")}</span>
                </div>
                <div>
                    <span className="time-units-word">Seconds</span>
                </div>
            </div>
        </div>
    )
}

export default Countdown
