import {
  FETCH_CALENDAR_REQUEST,
  FETCH_CALENDAR_SUCCESS,
  FETCH_CALENDAR_FAILURE,
  FETCH_CALENDAR_RESULTS_REQUEST,
  FETCH_CALENDAR_RESULTS_SUCCESS,
  FETCH_CALENDAR_RESULTS_FAILURE,
  FETCH_CALENDAR_NEXT_RACE_REQUEST,
  FETCH_CALENDAR_NEXT_RACE_SUCCESS,
  FETCH_CALENDAR_NEXT_RACE_FAILURE,
} from "../types/calendarTypes";
import axios from "axios";

export const fetchCalendar = (year = "current") => {
  return (dispatch) => {
    dispatch({ type: FETCH_CALENDAR_REQUEST });
    axios
      .get(`https://ergast.com/api/f1/${year}.json`)
      .then((response) => {
        const dispatchObject = {
          type: FETCH_CALENDAR_SUCCESS,
          payload: {
            year,
            seasonRaces: response.data.MRData.RaceTable.Races,
          },
        };

        if (year === "current") {
          const utcCurrentTime = Date.now();
          const nextRace = response.data.MRData.RaceTable.Races.find((race) => {
            const utcRaceTime = Date.parse(race.date + "T" + race.time); // Convert to UTC milliseconds
            return utcRaceTime > utcCurrentTime;
          });
          dispatchObject.payload.nextRace = nextRace;
        }
        dispatch(dispatchObject);
      })
      .catch((error) =>
        dispatch({
          type: FETCH_CALENDAR_FAILURE,
          payload: error.message,
        })
      );
  };
};

export const fetchCalendarResults = (year = "current") => {
  return (dispatch) => {
    dispatch({ type: FETCH_CALENDAR_RESULTS_REQUEST });
    axios
      .get(`https://ergast.com/api/f1/${year}/results.json?limit=999`)
      .then((response) => {
        dispatch({
          type: FETCH_CALENDAR_RESULTS_SUCCESS,
          payload: {
            year,
            seasonResults: response.data.MRData.RaceTable.Races,
          },
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_CALENDAR_RESULTS_FAILURE,
          payload: error.message,
        })
      );
  };
};

export const fetchCalendarNextRace = () => {
  return (dispatch) => {
    dispatch({ type: FETCH_CALENDAR_NEXT_RACE_REQUEST });
    axios
      .get(`https://ergast.com/api/f1/current/next.json`)
      .then((response) => {
        dispatch({
          type: FETCH_CALENDAR_NEXT_RACE_SUCCESS,
          payload: response.data.MRData.RaceTable.Races[0],
        });
      })
      .catch((error) =>
        dispatch({
          type: FETCH_CALENDAR_NEXT_RACE_FAILURE,
          payload: error.message,
        })
      );
  };
};
