import React from 'react';
import Select from 'react-select';

// Options -> [{value: "value", label: "label"}, {value: "value", label: "label"} ...]
// Parentcallback to handle state change in parent component.
const SelectInput = ({ options, parentCallBack }) => {

    const customStyles = {
        control: (styles, state) => ({
            ...styles,
            boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(225, 6, 0, 0.25)" : 0,
            borderColor: state.isFocused ? "rgba(225, 6, 0, 0.25)" : "#CED4DA",
            "&:hover": {
                borderColor: state.isFocused ? "rgba(225, 6, 0, 0.25)" : "#CED4DA"
            }
        }),
        option: (styles, state) => ({
            ...styles,
            backgroundColor: state.isSelected ? "rgba(225, 6, 0, 0.75)" : styles.color,
            borderBottom: "1px solid rgba(0, 0, 0, 0.125)",
            "&:hover": {
                color: "#FFF",
                backgroundColor: "rgba(225, 6, 0, 0.55)"
            }
        }),
    };

    return (
        <Select options={options} styles={customStyles} onChange={(option) => { parentCallBack(option.value) }} />
    )
}

export default SelectInput
