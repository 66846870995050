import {
    FETCH_CONSTRUCTOR_STANDINGS_REQUEST,
    FETCH_CONSTRUCTOR_STANDINGS_SUCCESS,
    FETCH_CONSTRUCTOR_STANDINGS_FAILURE,
    FETCH_CONSTRUCTOR_RESULTS_REQUEST,
    FETCH_CONSTRUCTOR_RESULTS_SUCCESS,
    FETCH_CONSTRUCTOR_RESULTS_FAILURE
} from "../types/constructorTypes";
import axios from "axios";

export const fetchConstructorStandings = (year = "current") => {
    return (dispatch) => {
        dispatch({ type: FETCH_CONSTRUCTOR_STANDINGS_REQUEST });
        axios.get(`https://ergast.com/api/f1/${year}/constructorStandings.json`)
            .then(response => dispatch({
                type: FETCH_CONSTRUCTOR_STANDINGS_SUCCESS,
                payload: {
                    year,
                    standings: response.data.MRData.StandingsTable.StandingsLists[0].ConstructorStandings
                }
            }))
            .catch(error => dispatch({
                type: FETCH_CONSTRUCTOR_STANDINGS_FAILURE,
                payload: error.message
            }));
    };
};

export const fetchConstructorResults = (constructorId, year = "current") => {
    return (dispatch) => {
        dispatch({ type: FETCH_CONSTRUCTOR_RESULTS_REQUEST });
        axios.get(`https://ergast.com/api/f1/${year}/constructors/${constructorId}/results.json`)
            .then(response => dispatch({
                type: FETCH_CONSTRUCTOR_RESULTS_SUCCESS,
                payload: {
                    year,
                    constructorId,
                    results: response.data.MRData.RaceTable.Races
                }
            }))
            .catch(error => dispatch({
                type: FETCH_CONSTRUCTOR_RESULTS_FAILURE,
                payload: error.message
            }));
    };
};