import React from 'react';
import { Row, Container } from "react-bootstrap";

const Footer = () => {
    return (
        <footer className="d-flex flex-column justify-content-center">
            <Container>
                <Row>
                    <p className="mx-auto mb-0 py-2">Copyright &copy; 2021 by Ivan Ansari</p>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer
