import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux";
import { fetchConstructorStandings, fetchConstructorResults } from "../redux/actions/constructorActions";
import { Alert, Container, Row, Col, Spinner } from 'react-bootstrap';
import TeamResultsTable from './TeamResultsTable';
import TeamInfoCard from './TeamInfoCard';
import axios from "axios";

const TeamProfileScreen = () => {

    const { constructorId: paramsConstructorId } = useParams();

    const history = useHistory();
    const dispatch = useDispatch();

    const constructorStandings = useSelector(state => state.constructorStandings);
    const { yearlyStandings } = constructorStandings;

    const constructorResultsData = useSelector(state => state.constructorResults);
    const { loading: loadingConstructorResults, error: errorConstructorResults, yearlyResults } = constructorResultsData;

    const [seasonResults, setSeasonResults] = useState("");
    const [constructorInfo, setConstructorInfo] = useState("");
    const [constructorDrivers, setConstructorDrivers] = useState("");
    const [constructorWiki, setConstructorWiki] = useState("");
    const [constructorWikiError, setConstructorWikiError] = useState("");

    useEffect(() => {
        document.title = "FN: Team Profile"
    }, []);

    // Grab season standings
    useEffect(() => {
        const currentYearStandings = yearlyStandings.find(standings => standings.year === "current");
        if (!currentYearStandings) {
            dispatch(fetchConstructorStandings());
        } else {
            const constructorInfo = currentYearStandings.standings.filter(constructorInfo => constructorInfo.Constructor.constructorId === paramsConstructorId);
            // If team queried doesn't exist, redirect.
            if (constructorInfo.length === 0) {
                history.push("/404");
            }
            setConstructorInfo(...constructorInfo);

        }
    }, [dispatch, yearlyStandings, setConstructorInfo, paramsConstructorId, history])

    // Grab season results.
    useEffect(() => {
        const currentYearResults = yearlyResults.find(yearlyResults => yearlyResults.year === "current" && yearlyResults.constructorId === paramsConstructorId)
        if (currentYearResults) {
            setSeasonResults(currentYearResults.results);
            setConstructorDrivers(currentYearResults.results[0].Results
                .map(driverResults => ({
                    driverId: driverResults.Driver.driverId,
                    givenName: driverResults.Driver.givenName,
                    familyName: driverResults.Driver.familyName
                })));
        } else {
            dispatch(fetchConstructorResults(paramsConstructorId));
        }
    }, [dispatch, yearlyResults, setSeasonResults, setConstructorDrivers, paramsConstructorId]);

    // Grab data from Wikipedia.
    useEffect(() => {
        if (constructorInfo) {
            const regex = /[^/]+$/;
            const constructorWikiTitle = constructorInfo.Constructor.url.match(regex)[0];
            // Get page image and extract for a team.
            axios.get(`https://en.wikipedia.org/w/api.php?format=json&action=query&prop=pageimages|extracts&piprop=original&exintro&explaintext&redirects=1&titles=${constructorWikiTitle}&origin=*`)
                .then(response => {
                    const pageId = Object.keys(response.data.query.pages)[0];
                    setConstructorWiki(response.data.query.pages[pageId]);
                })
                .catch(error => setConstructorWikiError(error.message));
        }
    }, [dispatch, constructorInfo, setConstructorWiki, setConstructorWikiError])

    return (
        <>
            {constructorWikiError
                ? <Alert variant="danger">{constructorWikiError}</Alert>
                : constructorWiki && constructorDrivers &&
                (
                    <Container className="page-container">
                        <h1 className="profile-title main-heading">{constructorWiki.title}</h1>

                        {/* Main info text */}
                        <Row>
                            <Col className="profile-main-info py-5">

                                {/* Profile info card */}
                                <TeamInfoCard constructorInfo={constructorInfo} constructorDrivers={constructorDrivers} constructorWiki={constructorWiki} />

                                {/* Wiki extract */}
                                <p>{constructorWiki.extract}</p>
                                <a href={constructorInfo.Constructor.url} className="wiki-link">Continue reading on Wikipedia...</a>
                            </Col>
                        </Row>

                        {/* Results table */}
                        <Row className="results-table team-results text-center py-5">
                            {loadingConstructorResults
                                ?
                                (
                                    <div className="d-flex justify-content-center w-100">
                                        <Spinner animation="border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </Spinner>
                                    </div>
                                )
                                : errorConstructorResults
                                    ? <Alert variant="danger">{errorConstructorResults}</Alert>
                                    : seasonResults &&
                                    <TeamResultsTable seasonResults={seasonResults} />
                            }
                        </Row>
                    </Container >
                )
            }
        </>
    )
}

export default TeamProfileScreen
