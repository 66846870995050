import React from 'react'
import { Link } from "react-router-dom";
import { Table } from 'react-bootstrap';
import { nationalities } from "../assets/nationalities";

const DriverInfoCard = ({ driverWiki, driverInfo }) => {
    return (
        <div className="card-container mx-auto mb-3 ml-sm-4 float-sm-right">

            {
                driverWiki.original
                    ? <img className="profile-card-picture" src={driverWiki.original.source} alt={`${driverWiki.title}`} />
                    : <img className="profile-card-picture" src={`/assets/constructors/${driverInfo.Constructors[0].constructorId}.jpg`} alt={`${driverWiki.title}`} />
            }

            <Table borderless size="sm">
                <tbody>
                    <tr>
                        <th>
                            Nationality
                        </th>
                        <td>
                            {driverInfo.Driver.nationality} <img className="national-flag" src={`/assets/flags/${nationalities.filter(nation => nation.nationality === driverInfo.Driver.nationality)[0].alpha_2_code.toLowerCase()}.svg`}
                                alt={`${driverInfo.Driver.nationality} flag`} />
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Position
                        </th>
                        <td>
                            <Link to={"/standings/drivers"}>
                                {driverInfo.positionText}
                            </Link>
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Wins
                        </th>
                        <td>
                            {driverInfo.wins}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Points
                        </th>
                        <td>
                            {driverInfo.points}
                        </td>
                    </tr>
                    <tr>
                        <th>
                            Team
                        </th>
                        <td>
                            <Link to={`/profile/team/${driverInfo.Constructors[0].constructorId}`}>
                                {driverInfo.Constructors[0].name}
                            </Link>
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}

export default DriverInfoCard
