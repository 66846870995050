import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCalendar,
  fetchCalendarResults,
  fetchCalendarNextRace,
} from "../redux/actions/calendarActions";
import { Alert, Container, Row, Col, Spinner } from "react-bootstrap";
import { nationalities } from "../assets/nationalities";
import ResultsButton from "./ResultsButton";

const CalendarScreen = () => {
  const dispatch = useDispatch();

  const calendarData = useSelector((state) => state.calendarData);
  const {
    loading: loadingSeasonRaces,
    error: errorSeasonRaces,
    calendars,
  } = calendarData;

  const calendarResults = useSelector((state) => state.calendarResults);
  const {
    loading: loadingSeasonResults,
    error: errorSeasonResults,
    results,
  } = calendarResults;

  const calendarNextRace = useSelector((state) => state.calendarNextRace);
  const {
    loading: loadingNextRace,
    error: errorNextRace,
    nextRace,
  } = calendarNextRace;

  const [seasonRaces, setSeasonRaces] = useState("");
  const [seasonResults, setSeasonResults] = useState("");

  const currentSeason = seasonRaces[0]?.season;
  const sameSeason = currentSeason === nextRace?.season;

  useEffect(() => {
    document.title = "FN: Calendar";
  }, []);

  useEffect(() => {
    const currentYearCalendar = calendars.find(
      (calendar) => calendar.year === "current"
    );
    if (currentYearCalendar) {
      setSeasonRaces(currentYearCalendar.seasonRaces);
    } else {
      dispatch(fetchCalendar());
    }
  }, [dispatch, calendars, setSeasonRaces]);

  useEffect(() => {
    const currentYearResults = results.find(
      (yearlyResults) => yearlyResults.year === "current"
    );
    if (currentYearResults) {
      setSeasonResults(currentYearResults.seasonResults);
    } else {
      dispatch(fetchCalendarResults());
    }
  }, [dispatch, results, setSeasonResults]);

  useEffect(() => {
    if (!nextRace) {
      dispatch(fetchCalendarNextRace());
    }
  });

  return (
    <Container className="page-container">
      {loadingSeasonRaces ? (
        <div className="d-flex justify-content-center w-100">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : errorSeasonRaces ? (
        <Alert variant="danger">{errorSeasonRaces}</Alert>
      ) : loadingNextRace ? (
        <div className="d-flex justify-content-center w-100">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : errorNextRace ? (
        <Alert variant="danger">{errorNextRace}</Alert>
      ) : (
        seasonRaces &&
        nextRace && (
          <>
            <h1 className="main-heading">{seasonRaces[0].season} Calendar</h1>
            <Row>
              {seasonRaces.map((race, idx) => (
                // Race information card
                <Col
                  key={idx}
                  xs={12}
                  md={
                    sameSeason && race.raceName === nextRace.raceName
                      ? { span: 10, offset: 1 }
                      : 6
                  }
                >
                  <Col
                    xs={{ span: 10, offset: 1 }}
                    className={`race-card ${
                      sameSeason && race.raceName === nextRace.raceName
                        ? "next-race-card"
                        : ""
                    } position-relative d-flex flex-column justify-content-center align-items-center px-0 pt-2`}
                  >
                    {" "}
                    <div className="px-4">
                      <div className="d-flex justify-content-between w-100">
                        <span>Round {race.round}</span>
                        {sameSeason && race.raceName === nextRace.raceName && (
                          <div className="next-race-tag">
                            <span>Next Race</span>
                          </div>
                        )}
                        <span>{race.date}</span>
                      </div>
                      <div className="grand-prix d-flex justify-content-center">
                        <span className="d-flex align-items-center">
                          {race.raceName}{" "}
                          <img
                            className="national-flag"
                            src={`/assets/flags/${nationalities
                              .filter(
                                (nation) =>
                                  race.Circuit.Location.country ===
                                  nation.en_short_name
                              )[0]
                              .alpha_2_code.toLowerCase()}.svg`}
                            alt={`${race.Circuit.Location.country} flag`}
                          />
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <img
                          className="img-fluid w-75 d-xl-none"
                          src={`/assets/circuits/${race.Circuit.circuitId}_small.png`}
                          alt={`${nextRace.raceName} circuit`}
                        />
                        <img
                          className="img-fluid w-100 d-none d-xl-block"
                          src={`/assets/circuits/${race.Circuit.circuitId}_big.png`}
                          alt={`${nextRace.raceName} circuit`}
                        />
                      </div>
                      <div className="d-flex justify-content-center">
                        <p>
                          Locality: {race.Circuit.Location.locality},{" "}
                          {race.Circuit.Location.country}{" "}
                        </p>
                      </div>
                    </div>
                    {/* If the race is previous to nextRace, add results */}
                    {(Number(race.round) < Number(nextRace.round) ||
                      !sameSeason) && (
                      <>
                        {/* <ResultsButton roundCardId={`results-round-${race.round}`} buttonText="RESULTS" /> */}
                        <div className="slide-in-reserved"></div>
                        <div
                          id={`results-round-${race.round}`}
                          className="race-card-results position-absolute h-100 w-100 d-flex flex-column align-items-center"
                        >
                          <ResultsButton
                            roundCardId={`results-round-${race.round}`}
                            buttonText="CIRCUIT"
                            className="race-toggle-btn mt-auto"
                          />
                          {loadingSeasonResults ? (
                            <div className="d-flex justify-content-center w-100">
                              <Spinner animation="border" role="status">
                                <span className="sr-only">Loading...</span>
                              </Spinner>
                            </div>
                          ) : errorSeasonResults ? (
                            <Alert variant="danger">{errorSeasonResults}</Alert>
                          ) : (
                            seasonResults && (
                              <>
                                <div className="results-container h-100 w-100">
                                  {seasonResults[race.round - 1].Results.map(
                                    (racePosition, index) => (
                                      <Link
                                        key={index}
                                        to={`/profile/driver/${racePosition.Driver.driverId}`}
                                        style={{
                                          textDecoration: "none",
                                          color: "#FFF",
                                        }}
                                      >
                                        <div
                                          className={`race-results ${
                                            racePosition.position < 4
                                              ? `race-results-${racePosition.position}`
                                              : ""
                                          } ${
                                            racePosition.Constructor
                                              .constructorId
                                          } pl-3 d-flex align-items-center justify-content-between`}
                                        >
                                          <span className="results-position d-flex justify-content-center align-items-center h-100">
                                            {racePosition.position}
                                          </span>
                                          <span className="driver-time flex-shrink-0 flex-grow-1 m-2 m-md-4">
                                            {racePosition.Time
                                              ? racePosition.Time.time
                                              : racePosition.positionText ===
                                                "R"
                                              ? "Retired"
                                              : racePosition.status}
                                          </span>
                                          <span className="driver-name d-inline-flex align-items-center justify-content-end flex-shrink-1 flex-grow-0 h-100">
                                            {racePosition.Driver.code}
                                            <img
                                              className="results-driver-picture img-fluid"
                                              src={`/assets/drivers/${racePosition.Driver.driverId}.png`}
                                              alt={`${racePosition.Driver.familyName}`}
                                            />
                                          </span>
                                        </div>
                                      </Link>
                                    )
                                  )}
                                </div>
                              </>
                            )
                          )}
                        </div>
                      </>
                    )}
                  </Col>
                </Col>
              ))}
            </Row>
          </>
        )
      )}
    </Container>
  );
};

export default CalendarScreen;
