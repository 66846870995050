export const FETCH_CALENDAR_REQUEST = "FETCH_CALENDAR_REQUEST";
export const FETCH_CALENDAR_SUCCESS = "FETCH_CALENDAR_SUCCESS";
export const FETCH_CALENDAR_FAILURE = "FETCH_CALENDAR_FAILURE";

export const FETCH_CALENDAR_RESULTS_REQUEST = "FETCH_CALENDAR_RESULTS_REQUEST";
export const FETCH_CALENDAR_RESULTS_SUCCESS = "FETCH_CALENDAR_RESULTS_SUCCESS";
export const FETCH_CALENDAR_RESULTS_FAILURE = "FETCH_CALENDAR_RESULTS_FAILURE";

export const FETCH_CALENDAR_NEXT_RACE_REQUEST =
  "FETCH_CALENDAR_NEXT_RACE_REQUEST";
export const FETCH_CALENDAR_NEXT_RACE_SUCCESS =
  "FETCH_CALENDAR_NEXT_RACE_SUCCESS";
export const FETCH_CALENDAR_NEXT_RACE_FAILURE =
  "FETCH_CALENDAR_NEXT_RACE_FAILURE";
