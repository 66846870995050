import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchDriverStandings } from "../redux/actions/driverActions";
import { Alert, Container, Row, Col, Spinner } from "react-bootstrap";

const DriverStandingsScreen = () => {
  const dispatch = useDispatch();

  const driverStandings = useSelector((state) => state.driverStandings);
  const { loading, error, yearlyStandings } = driverStandings;

  const [standings, setStandings] = useState("");

  useEffect(() => {
    document.title = "FN: Driver Standings";
  }, []);

  useEffect(() => {
    const currentYearStandings = yearlyStandings.find(
      (standings) => standings.year === "current"
    );
    if (!currentYearStandings) {
      dispatch(fetchDriverStandings());
    } else {
      setStandings(currentYearStandings.standings);
      console.log(yearlyStandings);
    }
  }, [dispatch, yearlyStandings, setStandings]);

  return (
    <Container className="page-container">
      <h1 className="main-heading">Driver standings</h1>
      {loading ? (
        <div className="d-flex justify-content-center w-100">
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : (
        standings && (
          <>
            <Row>
              {standings.map((driver, index) => (
                <Col md={6} key={index}>
                  <Link
                    to={`/profile/driver/${driver.Driver.driverId}`}
                    style={{ textDecoration: "none" }}
                  >
                    <div
                      className={`driver-card ${driver.Constructors[0].constructorId}`}
                    >
                      <Row>
                        <span id="sdg-points">
                          {driver.points}{" "}
                          {Number(driver.points) === 1 ? "point" : "pts"}
                        </span>
                        <Col
                          xs={6}
                          xl={7}
                          className="d-flex flex-column justify-content-between"
                        >
                          <Row className="d-flex p-2 ">
                            <Col
                              className={`driver-card-logo ${driver.Constructors[0].constructorId} col-auto d-flex justify-content-center align-items-center`}
                            >
                              <img
                                className="constructor-logo"
                                src={`/assets/constructors/${driver.Constructors[0].constructorId}.jpg`}
                                alt={`${driver.Constructors[0].name}`}
                              />
                            </Col>
                            <Col className="d-flex flex-column my-2">
                              <span id="sdg-given-name">
                                {driver.Driver.givenName}
                              </span>
                              <span id="sdg-family-name">
                                {driver.Driver.familyName}
                              </span>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xs={6}
                          xl={5}
                          className="position-relative d-flex align-items-end"
                        >
                          <img
                            className="driver-picture img-fluid"
                            src={`/assets/drivers/${driver.Driver.driverId}.png`}
                            alt={`${driver.Driver.familyName}`}
                          />
                        </Col>
                        <span id="sdg-position">{driver.position}</span>
                      </Row>
                    </div>
                  </Link>
                </Col>
              ))}
            </Row>
          </>
        )
      )}
    </Container>
  );
};

export default DriverStandingsScreen;
